<template>
  <section class="product-cancellation">

    <div class="travel-tabs">
      <div class="tab-icons">tabs</div>
      <div class="tab-content">tab-content</div>
    </div>
    <pre>{{ productToCancel }}</pre>

    <aDivider class="mb-10" />

    <aRow class="footer" :gutter="20">
      <aCol class="disclaimer f10" :span="18">
        <b>Atenção:</b> Ao prosseguir com o cancelamento desta venda, uma carta
        de crédito será automaticamente gerada para o cliente.
      </aCol>
      <aCol :span="6">
        <aButton class="f12" type="danger" size="small">
          <img
            class="mr-10"
            src="@/assets/images/dashboard/contracts/cancel-sale.png"
            alt="icon"
            width="15"
          />
          Cancelar Produto</aButton
        >
      </aCol>
    </aRow>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "CancelFlightModal",
  props: {
    productToCancel: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.product-cancellation {
  .footer {
    .disclaimer {
      color: #3c3c3c;
      font-weight: 500;
      b {
        color: #ff4545;
      }
    }

    button {
      padding: 0px 20px;
      font-weight: 500;
    }
  }
}
</style>
